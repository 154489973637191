// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-containers-specialnote-js": () => import("/opt/build/repo/src/containers/specialnote.js" /* webpackChunkName: "component---src-containers-specialnote-js" */),
  "component---src-containers-normal-note-js": () => import("/opt/build/repo/src/containers/normalNote.js" /* webpackChunkName: "component---src-containers-normal-note-js" */),
  "component---src-containers-category-js": () => import("/opt/build/repo/src/containers/category.js" /* webpackChunkName: "component---src-containers-category-js" */),
  "component---src-containers-incident-single-js": () => import("/opt/build/repo/src/containers/incidentSingle.js" /* webpackChunkName: "component---src-containers-incident-single-js" */),
  "component---src-containers-general-js": () => import("/opt/build/repo/src/containers/general.js" /* webpackChunkName: "component---src-containers-general-js" */),
  "component---src-pages-404-en-js": () => import("/opt/build/repo/src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("/opt/build/repo/src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-contact-en-js": () => import("/opt/build/repo/src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contacto-es-js": () => import("/opt/build/repo/src/pages/contacto.es.js" /* webpackChunkName: "component---src-pages-contacto-es-js" */),
  "component---src-pages-impact-en-js": () => import("/opt/build/repo/src/pages/impact.en.js" /* webpackChunkName: "component---src-pages-impact-en-js" */),
  "component---src-pages-incidencia-es-js": () => import("/opt/build/repo/src/pages/incidencia.es.js" /* webpackChunkName: "component---src-pages-incidencia-es-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("/opt/build/repo/src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-investigative-reporting-en-js": () => import("/opt/build/repo/src/pages/investigative_reporting.en.js" /* webpackChunkName: "component---src-pages-investigative-reporting-en-js" */),
  "component---src-pages-news-en-js": () => import("/opt/build/repo/src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-noticias-especiales-es-js": () => import("/opt/build/repo/src/pages/noticias-especiales.es.js" /* webpackChunkName: "component---src-pages-noticias-especiales-es-js" */),
  "component---src-pages-noticias-es-js": () => import("/opt/build/repo/src/pages/noticias.es.js" /* webpackChunkName: "component---src-pages-noticias-es-js" */),
  "component---src-pages-preview-js": () => import("/opt/build/repo/src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

